<template>
  <div
    v-if="image && image.url"
    ref="imageElement"
    class="strapi-bg-image"
    :style="{
      'background-image': imagePath,
      'background-repeat': repeat,
    }"
  ></div>
</template>

<script setup lang="ts">
import type { StrapiImage } from '~/apollo/types/types';
import { RepeatType } from '~/components/strapi/types';

const props = defineProps({
  image: {
    type: Object as PropType<StrapiImage>,
    required: true,
  },
  repeat: {
    type: String as PropType<RepeatType>,
    default: RepeatType.NO,
  },
});

const imageElement = ref<HTMLElement>();
const imageElementVisible = useElementVisibility(imageElement);
const wasVisible = ref<boolean>(false);

const imagePath = computed<string>(() => {
  if (imageElementVisible.value || wasVisible.value) {
    return `url(${props.image.url})`;
  }

  return undefined;
});

watch(
  () => imageElementVisible.value,
  (val: boolean) => {
    if (val === true) {
      wasVisible.value = true;
    }
  },
);
</script>

<style lang="scss" scoped>
.strapi-bg-image {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: top;
}
</style>
