<template>
  <div
    v-if="component.frequentlyAskedQuestions.length > 0"
    class="landingpage-faq w-100 h-100"
  >
    <strapi-background-image
      :image="component.backgroundImage"
      :repeat="RepeatType.Y"
    />
    <div data-cy="faq-wrapper" class="container landingpage-faq__content">
      <h2>{{ component.title }}</h2>
      <div
        class="row row-cols-1 row-cols-lg-2 align-items-start align-self-start"
      >
        <div
          v-for="(question, index) in component.frequentlyAskedQuestions"
          :key="question.id"
          ref="faqCards"
          :data-cy="dataCyName([question.title, 'faq'])"
          class="col"
        >
          <div class="card p-5 mb-5 border-rounded">
            <div
              class="card-header row align-items-center cursor-pointer"
              @click="(evt) => toggleAnswer(evt, index)"
            >
              <div class="col col-sm-1 col-2 text-center d-inline lh-lg">
                <strapi-image
                  :force-width="25"
                  :image="question.icon"
                  :alt="question.title"
                />
              </div>
              <div class="col col-sm-10 col-8">
                {{ question.title }}
              </div>
              <div class="col col-sm-1 col-2 text-center d-inline">
                <fa-icon
                  v-if="index === openAnswerIndex"
                  class="fs-tiny text-rhino"
                  :icon="faMinus"
                  aria-hidden="true"
                />
                <fa-icon
                  v-else
                  class="fs-tiny text-rhino"
                  :icon="faPlus"
                  aria-hidden="true"
                />
              </div>
            </div>

            <div
              class="card-body py-5 mt-5"
              :class="{ collapse: index !== openAnswerIndex }"
            >
              {{ question.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiFaqList, StrapiProductFaq } from '~/apollo/types/types';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';
import dataCyName from '~/helpers/cypress';
import { RepeatType } from '~/components/strapi/types';

export default defineComponent({
  name: 'StrapiProductFaq',
  props: {
    component: {
      type: Object as PropType<StrapiProductFaq | StrapiFaqList>,
      required: true,
    },
  },
  setup() {
    const faqCards = ref<Array<HTMLElement>>([]);
    const { lg } = useAppBreakpoints();

    const openAnswerIndex = ref(-1);

    const toggleAnswer = (evt: MouseEvent, index) => {
      const isClosing = openAnswerIndex.value === index;

      if (isClosing) {
        // Close the active answer
        openAnswerIndex.value = -1;
      } else {
        openAnswerIndex.value = index;
      }

      if (lg.value) {
        const targetCard = faqCards.value[index];
        const beforeHeight = targetCard.getBoundingClientRect().height;

        nextTick(() => {
          const afterHeight = targetCard.getBoundingClientRect().height;

          if (isClosing) {
            // on closing, we need to remove all negative margins
            faqCards.value.forEach((faqCard) => {
              faqCard.style.marginTop = '';
            });
          } else {
            // first determine the column of the opened card
            const targetIsInColumn = index % 2 === 0;
            // we need a starting index to apply the negative margin
            const startingIndex = targetIsInColumn ? index + 2 : index;

            // now go through every card and validate if applying the negative margin is necessary
            faqCards.value.forEach((faqCard, faqCardIndex) => {
              // we need to apply the negative margin to the other column
              const cardIsInOtherColumn = targetIsInColumn
                ? faqCardIndex % 2 !== 0
                : faqCardIndex % 2 === 0;

              if (faqCardIndex > startingIndex && cardIsInOtherColumn) {
                // now apply the negative margin which is the height of the opened answer section
                faqCard.style.marginTop = `${beforeHeight - afterHeight}px`;
              } else {
                // reset the margin for the other cards
                faqCard.style.marginTop = '';
              }
            });
          }
        });
      }
    };

    const screenSizeLgWatchHandler = watch(
      () => lg.value,
      (newValue) => {
        // reset the margin when the screen size changes
        faqCards.value.forEach((card) => {
          card.style.marginTop = '';
        });
      },
    );

    onMounted(() => {
      screenSizeLgWatchHandler();
    });

    return {
      faPlus,
      faMinus,

      RepeatType,
      faqCards,
      toggleAnswer,
      openAnswerIndex,
      dataCyName,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';

.landingpage-faq {
  position: relative;

  &__content {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    h2 {
      margin-bottom: 1.25rem;
    }
  }

  .card {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $gp-dark-mouse;

    &-header {
      border: 0;
      background-color: $gp-dark-mouse;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1;
      padding: 0;
    }

    &-body {
      border-top: 1px solid $gp-rhino;
    }
  }
}
</style>
